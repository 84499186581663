
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";
import playIcon from './assets/img/play.svg'

const firebaseConfig = {
  apiKey: "AIzaSyCQ361NTWRRb3NDYlUvErc_fL6cdgMY1UU",
  authDomain: "laithier-portfolio.firebaseapp.com",
  projectId: "laithier-portfolio",
  storageBucket: "laithier-portfolio.appspot.com",
  messagingSenderId: "342872180046",
  appId: "1:342872180046:web:df5f26770e87534a4a8a1f"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
let allProjects

const initProjects = async () => {
  const projectsRef = collection(db, "projects");
  const projectsDiv = document.querySelector('.projects')

  const q = query(
    projectsRef, 
    where("isActive", "==", true)
  );
  allProjects = await getDocs(q);
  allProjects.forEach((doc) => {
    let project = doc.data()
    projectsDiv.innerHTML += `
    <li id="${doc.id}" data-aos="fade-up" class="flex flex-col snap-center bg-white bg-opacity-5 rounded-2xl p-3 mr-8 hover:cursor-pointer project">
      <div class="-md:w-[342] -md:h-[180] w-[470] h-[248] block mb-6 relative">
      <img src="${project.photoUrl}" class="img absolute top-0 left-0 rounded-md h-full w-full transition-all duration-500">  
      <img class="play opacity-0 transition-all duration-500 absolute left-1/2 top-1/2 w-[40px] -translate-y-1/2 -translate-x-1/2" src="${playIcon}" alt="Lire la vidéo" />
      </div>

      <p class="mt-3 font-bold">${project.title}</p>

      <div class="flex justify-between text-[#656565] text-sm mt-6">
        <p>${project.place}</p>
        <p>${project.date}</p>
      </div>
    </li>
    `;
  });

}

const initVideosHover = () => {
  document.querySelector('.overlay-bkg').addEventListener('click', () => {
    document.querySelector(".project-overlay").style.opacity = "0%"
    document.querySelector(".project-overlay").style.pointerEvents = "none"
    document.querySelector(".youtubeIframe").src = ""
  })

  document.querySelectorAll('.projects li').forEach(project => {
    project.addEventListener('click', () => {
      const projectData = allProjects.docs.find(projectData => projectData.id === project.id)
      document.querySelector(".youtubeIframe").src = projectData.data().videoUrl
      //project.querySelector("video").pause()
      document.querySelector(".project-overlay").style.opacity = "100%"
      document.querySelector(".project-overlay").style.pointerEvents = "all"
    })
    
    project.addEventListener('mouseover', () => {
      project.querySelector("img").style.opacity = "20%"
      project.querySelector(".play").style.opacity = "100%"
    })
    project.addEventListener('mouseout', () => {
     // project.querySelector("video").pause()
      //project.querySelector("video").currentTime = 0
      project.querySelector(".play").style.opacity = "0%"
      project.querySelector("img").style.opacity = "100%"
    })
  })
}

const listenProjectsSlider = () => {
  const prevProjectBtn = document.querySelector('.prevProject')
  const nextProjectBtn = document.querySelector('.nextProject')
  const projects = document.querySelector('.projects')
  let currentProject = 1
  const projectDivWidth = document.querySelector('.projects li').offsetWidth + 32

  prevProjectBtn.addEventListener('click', () => {
    if (currentProject === 1) return

    currentProject -= 1
    if (currentProject === 1)  {
      prevProjectBtn.style.opacity = "50%"
    } else {
      prevProjectBtn.style.opacity = "100%"
    }
    nextProjectBtn.style.opacity = "100%"
    projects.style.transform = `translateX(-${(currentProject - 1) * projectDivWidth}px)`
  })

  nextProjectBtn.addEventListener('click', () => {
    const allProjects = document.querySelectorAll('.projects li')
    if (currentProject === allProjects.length - 1) return
    prevProjectBtn.style.opacity = "100%"

    projects.style.transform = `translateX(-${(currentProject) * projectDivWidth}px)`
    currentProject += 1

    if (currentProject === allProjects.length - 1)  {
      nextProjectBtn.style.opacity = "50%"
    } else {
      nextProjectBtn.style.opacity = "100%"
    }
  })
}

initProjects().then(() => {
  initVideosHover();
  listenProjectsSlider();
});

